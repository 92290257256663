.DatePicker {
  position: relative;
  @media screen and (max-width: 480px) {
    position: static;
  }
}

.select {
  flex-grow: 1;

  justify-content: center;

  cursor: pointer;
  display: flex;

  align-items: center;
  gap: 20px;
  border-radius: 20px;

  padding: 0px 20px;

  & > span {
    font-size: 14px;
    font-weight: bold;
    line-height: 40px;
  }
}

.picker {
  z-index: 99;
  border-radius: 8px;

  @media screen and (max-width: 480px) {
    left: -200px;
    flex-direction: column-reverse;
    width: 100%;
  }
}

.calendarContainer {
  position: absolute;
  overflow: hidden;
  top: 50px;
  left: -320px;
  z-index: 99;
  box-shadow: 0px 10px 30px #0c1d8012;
  border-radius: 8px;
  background: white;
  @media screen and (max-width: 480px) {
    flex-direction: column-reverse;
    left: 50%;
    transform: translate(-50%);
    top: 64px;
    width: 358px;
  }
}

.btnContainer {
  background: white;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 56px;
  border-top: solid 2px var(--divider-gray);
  margin-top: 16px;
}

.icon {
  transition: all 300ms ease;
}

.mobileIcon {
  border: 1px solid rgba(156, 164, 173, 0.2);
  border-radius: 40px;
  padding: 16px;
  height: 40px;
  width: 40px;
  z-index: 154;

  > img {
    position: relative;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 20px;
    height: 20px;
  }
}
