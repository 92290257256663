.tableBody {
  width: 100%;
  display: flex;
  flex-direction: column;
  tr {
    border-bottom: 1px solid var(--divider-gray);
    z-index: inherit;

    td {
      padding: 12px 6px;
    }
    td:first-child {
      flex: 4 0 auto;
    }
  }
}

.workspaceManagerTableBody {
  height: 100%;
  overflow: auto;
  @media screen and (max-width: 480px) {
    overflow-y: hidden;
  }
}

.workspaceUsersTableBody {
  tr {
    &:last-child {
      border-bottom: none;
    }
  }
}

.campaignRow {
  display: flex;
  align-items: center;
  min-height: 50px;
  background-color: white;
  box-sizing: content-box;
  cursor: pointer;
  td {
    background-color: white;
  }
  &:hover {
    td {
      background-color: #f4f5f799 !important;
      background-color: #f4f5f7 !important;
    }
  }

  &.sticky {
    &:hover {
      td {
        background-color: #f4f5f7 !important;
      }
    }
  }
  transition: all 0.15s ease-in-out;
}

.workspaceManagerRow {
  td {
    display: flex;
    height: 50px;
    max-height: 50px;
    min-height: 50px;
    padding: 0 6px;
  }
  @media screen and (max-width: 480px) {
    height: 50px;
    max-height: 50px;
    min-height: 50px;
    td {
      display: flex;
      height: unset;
      max-height: unset;
      min-height: unset;
      padding: 12px 6px;
    }
  }
}

.rowGeneric {
  display: flex;
  align-items: center;
  position: relative;
}

.rowCellGeneric {
  box-sizing: border-box;
  flex-shrink: 0;
  flex-basis: auto;
}

.alignedCenter {
  justify-content: center;
  text-align: center;
}
.alignedFlexStart {
  justify-content: flex-start;
  text-align: left;
}
.alignedFlexEnd {
  justify-content: flex-end;
  text-align: right;
}

.unapproved,
.active,
.empty,
.deactivated {
  position: relative;
}

.deactivated {
  background: #a0a0a01a;
  color: var(--text-gray);
  border-radius: 4px;
}

.empty {
  background: #ff40401a;
  color: var(--alert);
}

.sticky {
  position: sticky;
}

.pointer {
  cursor: pointer;
}

.noLineHeight {
  div {
    line-height: 0;
  }
  padding: 0;
}

.breakeven {
  color: var(--text-black);
  font-weight: 600;
}

.profit {
  font-weight: 600;
  color: var(--success);
}

.loss {
  font-weight: 600;
  color: var(--alert);
}

.campaignsTableBody {
  border-radius: 4px;

  td {
    border-left: 1px solid var(--divider-gray);
    display: flex;
    align-items: center;
    z-index: 1;
    height: 50px;
    &:first-child,
    &:nth-child(2) {
      position: sticky;
      z-index: 3;
    }
    &:first-child {
      left: 0;
    }
    &:nth-child(2) {
      left: 54px;
      border-right: 1px solid var(--divider-gray);
    }
    &:nth-child(3) {
      border-left: none;
    }
    &:last-child {
      border-right: 1px solid var(--divider-gray);
    }

    @media screen and (max-width: 1500px) {
      padding: 0 6px !important;
    }
  }
}

.tableBodyCellContent {
  font-size: 13px;
  letter-spacing: 0px;
}

.boldText {
  font-weight: bold;
}

.chevronContainer {
  padding-left: 0 !important;
}
