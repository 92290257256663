.content {
  width: 450px;
  min-height: 200px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 20px;
  text-align: center;
  .text {
    text-align: center;
    line-height: 26px;
    span {
      font: normal normal bold 14px/26px Metropolis, sans-serif;
    }
  }
  .buttons {
    display: flex;
    gap: 10px;
    justify-content: center;
    margin-top: 10px;
  }
}
