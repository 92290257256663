.column {
  gap: 20px;
  flex-direction: column;
  display: flex;
  position: relative;
  left: 270px;
  margin-bottom: 40px;
  flex-shrink: 1;
  max-width: min(calc(100% - 270px), 700px);
  @media screen and (max-width: 1200px) {
    left: 0;
    max-width: unset;
  }
}

.cardRow {
  display: flex;
  flex-direction: row;
  gap: 20px;
}

.layout {
  display: flex;
  gap: 20px;
  flex-direction: column;
  width: 100%;
  height: 100%;
  justify-content: center;
  align-items: "center";
}

.rest {
  display: flex;
  flex-direction: row;
  gap: 70px;

  @media screen and (max-width: 1200px) {
    flex-direction: column;
  }
  @media screen and (max-width: 480px) {
    gap: 36px;
  }
}

.importer {
  display: flex;
  flex-direction: row;
  gap: 10px;
  align-items: center;

  .main {
    flex-grow: 1;
    gap: 6px;
    display: flex;
    flex-direction: column;

    .title {
      font: normal normal bold 14px/14px Metropolis, sans-serif;
      letter-spacing: 0px;
    }
    .subtitle {
      font: normal normal normal 14px/14px Metropolis, sans-serif;
      letter-spacing: 0px;
    }
  }
}

.instructions {
  display: flex;
  flex-direction: column;
  gap: 30px;
}

.video {
  display: flex;
  justify-content: center;
  width: 100%;
  position: relative;
  padding-bottom: 50%;
  padding-top: 25px;
  height: 0;
  iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
}

.modal {
  height: fit-content !important;
  width: fit-content !important;
}

.cardHead {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.small {
  padding: 0px 16px !important;
  font: normal normal bold 12px/14px Metropolis, sans-serif;
  letter-spacing: 0px;
  background: white;
  color: var(--black);
  box-shadow: none;
  border: 1px solid var(--input-outline);
  span {
    font-size: 10px;
  }
}

.adsActive {
  font: normal normal bold 14px/26px Metropolis, sans-serif;
  color: var(--blue-light) !important;
}

.active {
  position: absolute;
  left: -14px;
  background-color: var(--bg-gray);
  border-radius: 4px;
  padding: 10px 0px 10px 20px;
  z-index: -2;
  width: calc(100% + 14px);
  height: 100%;
}

.activeText {
  color: var(--black) !important;
  font: normal normal bold 14px/26px Metropolis, sans-serif;
}

.customChange {
  background: rgba(0, 255, 0, 0.3);
}

.customInfo {
  display: flex;
  flex-direction: column;
  gap: 16px;
  h3 {
    font: normal normal bold 14px/22px Metropolis, sans-serif;
  }
}

.mainText {
  margin-top: 12px;
}

.mainAdText {
  margin-bottom: 26px;
}

.fbTracking {
  margin-top: 24px;
  position: relative;
}
