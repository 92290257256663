@import "../../index.scss";

.inputWrapper {
  display: flex;
  flex-direction: column;
  gap: 10px;
  font: inherit;
  font-size: 14px;
  &--vertical {
    flex-direction: row;
    align-items: center;
    justify-content: space-between;

    @media screen and (max-width: 480px) {
      flex-direction: column;
      align-items: flex-start;
    }
  }
}

.label {
  font: inherit;
  font-size: 14px;
  font-weight: 600;
}

.inputFieldWrapper {
  position: relative;
  &--vertical {
    min-width: 420px;
    flex: 1;
  }
  @media screen and (max-width: 480px) {
    width: 100%;
    &--vertical {
      min-width: unset;
    }
  }
}

.input {
  @extend .basic-input;
  color: var(--text-black);
  width: 100%;
  box-sizing: border-box;
  padding: 14px 10px 14px 12px;
  height: 50px;
  border: none;
  font: inherit;
  font-size: 14px;
  &::placeholder {
    color: var(--text-gray);
  }

  &:focus {
    outline: none;
    border: 1px solid var(--blue);
  }
  &.error {
    background: #fff7f5;
    border: 1px solid var(--alert);
  }
}

.inputWithIcon {
  padding: 14px 10px 14px 65px;
}

.icon {
  position: absolute;
  top: 50%;
  background-repeat: no-repeat !important;
  left: 24px;
  transform: translate(0, -50%);
  width: 24px;
  height: 24px;
}
