@import "../../index.scss";

.SidePanel {
  background-color: #fff;
  height: 100%;
  flex-basis: 250px;
  box-sizing: border-box;
  padding-right: 0;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.logo {
  padding: 23px 20px 23px 24px;
  display: flex;
  align-items: center;
  border-bottom: 2px solid var(--background-gray);
  > img {
    width: 154px;
  }
}

.navigation {
  margin-top: 20px;
  display: flex;
  flex-direction: row;
  flex-grow: 1;
  padding-left: 24px;
  justify-content: space-between;
  gap: 10px;

  .wrapper {
    position: relative;
    gap: 10px;
    display: flex;
    width: 100%;
    flex-direction: column;

    & > a {
      padding: 10px 0;
    }

    & > a[href="/workspace-setup"] {
      margin-top: auto;
      padding-bottom: 0;
    }
  }
}

.workspaceLink {
  font-size: 14px;
  grid-gap: 15px;
  font-weight: 400;
  gap: 15px;
  color: var(--text-black);
  display: flex;
  flex-direction: row;
  align-items: center;
  line-height: 0;
  & > div {
    line-height: 0;
  }
}

.bottom {
  align-items: baseline;
  font-size: 14px;
  margin-bottom: 30px;
  padding-left: 20px;
  display: flex;
}
