.attributionWindowModal {
  width: 500px;
  height: fit-content;
  background: var(--unnamed-color-ffffff) 0% 0% no-repeat padding-box;
  background: #ffffff 0% 0% no-repeat padding-box;
  border-radius: 15px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.text {
  font: normal normal normal 14px/26px Metropolis, sans-serif;
  letter-spacing: -0.14px;
  color: var(--black);
  text-align: center;
  margin: 24px 0 33px 0;
}

.button {
  width: 140px;
  button {
    width: 100%;
  }
}
