.Layout {
  width: 100%;
  height: 100%;
  padding: 0 48px 48px;
  overflow-y: auto;
  overflow-x: hidden;

  position: relative;

  @media (max-width: 1200px) {
    margin: 88px 0 92px 0;
    padding: 0 48px;
  }
  @media (max-width: 480px) {
    padding: 16px;
    overflow: hidden;
    margin: 0;
  }
}

.content {
  display: flex;
  align-items: stretch;
  gap: 20px;
  min-height: calc(100% - 96px);
  & > * {
    flex: 1;
  }

  z-index: 1;

  position: relative;
  top: 96px;

  @media screen and (max-width: 1200px) {
    flex-direction: column;
    top: 0;
    height: unset;
    min-height: 100%;
  }
  @media screen and (max-width: 480px) {
    height: unset;
    overflow: hidden;
    position: relative;
    top: 80px;
    margin: 0 0 120px 0;
  }
}

.addWorkspaceMobile {
  @media screen and (max-width: 480px) {
    padding: 0;
    .content {
      position: static;
    }
  }
}
