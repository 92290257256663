.selectProviderModal {
  width: 500px;
  height: fit-content;
  background: var(--unnamed-color-ffffff) 0% 0% no-repeat padding-box;
  background: #ffffff 0% 0% no-repeat padding-box;
  border-radius: 15px;
  display: flex;
  flex-direction: column;
  align-items: center;

  @media screen and (max-width: 480px) {
    width: 350px;
    padding: 50px 20px;
  }
}

.selectProviderContainer {
  display: grid;
  gap: 16px;
  margin-bottom: 36px;
}

.selectProviderItem {
  border: 1px solid var(--input-outline);
  border-radius: 8px;
  width: 126px;
  padding: 12px;
  gap: 6px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  user-select: none;
  position: relative;
}

.checked {
  border: 1px solid var(--blue-light);
}

.platformName {
  font: normal normal normal 12px/26px Metropolis, sans-serif;
  letter-spacing: -0.12px;
}

.platformCheckbox {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

.customCheckmark {
  align-self: flex-end;
  height: 16px;
  width: 16px;
  background-color: white;
  border-radius: 50%;
  border: 1px solid var(--icon-gray);
  > * {
    transform: translate(-50%, -50%);
    position: relative;
    top: 50%;
    left: 50%;
  }
}

.platformCheckbox:checked {
  ~ .customCheckmark {
    background-color: var(--blue-light);
    border: none;
  }
}
.platformCheckbox:disabled ~ .customCheckmark {
  background-color: var(--icon-gray);
}

.text {
  font: normal normal normal 14px/26px Metropolis, sans-serif;
  letter-spacing: -0.14px;
  color: var(--black);
  text-align: center;
  margin: 21px 0 30px 0;
}

.header {
  text-align: center;
  font: normal normal bold 24px/29px Metropolis, sans-serif;
  letter-spacing: 0px;
  color: var(--black);
  margin-top: 8px;
}

.buttons {
  display: flex;
  gap: 16px;
  justify-content: center;
  button {
    &:last-child {
      width: 154px;
      padding: 0 20px;
    }
  }

  @media screen and (max-width: 480px) {
    button {
      padding: 0 25px;
    }
  }
}
