.container {
  display: flex;
  align-items: center;
  span {
    margin-left: 4px;
    margin-right: 4px;
    font: normal normal bold 12px/14px Metropolis, sans-serif;
    letter-spacing: 0px;
    color: var(--black);
  }
}
