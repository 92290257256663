.RegisterPage {
  display: flex;
  flex-flow: row wrap;
  width: 100%;
  height: 100%;
}

.firstHalf {
  display: flex;
  flex-direction: column;
  width: 50%;
  background-color: white;
  box-sizing: border-box;
  flex-grow: 1;
  align-items: center;

  @media screen and (max-width: 768px) {
    justify-content: center;
    gap: 16px;
    padding: 30px 20px;
  }
}

.firstHalfContent {
  display: flex;
  flex-direction: column;
  width: 60%;
  max-width: 512px;
  margin: auto 0;
  gap: 48px;
  @media screen and (max-width: 768px) {
    width: 80%;
    max-width: unset;
    gap: 8px;
  }
  @media screen and (max-width: 480px) {
    width: 100%;
  }
}

.secondHalf {
  display: flex;
  width: 50%;
  background-color: #3d33a9;
  color: white;
  text-rendering: optimizeLegibility;
}

.form {
  display: flex;
  flex-flow: column wrap;
}

.column {
  display: flex;
  flex-direction: column;
  gap: 16px;
}

.name {
  display: flex;
  flex-grow: 10;

  gap: 20px;
  @media screen and (max-width: 1350px) {
    flex-direction: column;
  }
}

.subtitle {
  font: normal normal normal 14px/26px Metropolis, sans-serif;
}

.button {
  margin-top: 30px;
  margin-bottom: 10px;
}

.logo {
  display: flex;
  height: 32px;
  margin-bottom: 30px;
  @media screen and (max-width: 480px) {
    position: static;
  }
}

.buttons {
  margin-top: 12px;
  display: flex;
  gap: 20px;
  font-size: 14px;
  flex-direction: row;
}