.selectProviderModal {
  width: 500px;
  padding: 58px 45px 48px 45px;
  background: var(--unnamed-color-ffffff) 0% 0% no-repeat padding-box;
  background: #ffffff 0% 0% no-repeat padding-box;
  border-radius: 15px;
  display: flex;
  flex-direction: column;
  align-items: center;

  @media screen and (max-width: 480px) {
    width: 350px;
    padding: 50px 20px;
    height: min(90%, 850px);
    justify-content: flex-start;
    overflow: auto;
  }
}

.selectProviderContainer {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-template-rows: repeat(2, 1fr);
  gap: 16px;
  margin-bottom: 36px;
}

.selectProviderItem {
  border: 1px solid var(--input-outline);
  border-radius: 8px;
  width: 126px;
  padding: 12px;
  gap: 6px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  user-select: none;
}

.checked {
  border: 1px solid var(--blue-light);
}

.platformName {
  font: normal normal normal 12px/26px Metropolis, sans-serif;
  letter-spacing: -0.12px;
}

.platformRadioBtn {
  align-self: flex-end;
  appearance: none;
  background-color: #fff;
  margin: 0;
  font: inherit;
  color: currentColor;
  width: 16px;
  height: 16px;
  border: 1px solid var(--icon-gray);
  border-radius: 50%;
  place-content: center;
  display: grid;
}

.platformRadioBtn:checked {
  border: 1px solid var(--blue-light);
}

.platformRadioBtn::before {
  content: "";
  width: 8px;
  height: 8px;
  border-radius: 50%;
  transform: scale(0);
  transition: 120ms transform ease-in-out;
  box-shadow: inset 1em 1em var(--blue-light);
}

.platformRadioBtn:checked::before {
  transform: scale(1);
}

.text {
  font: normal normal normal 14px/26px Metropolis, sans-serif;
  letter-spacing: -0.14px;
  color: var(--black);
  text-align: center;
  margin: 21px 0 30px 0;
}

.header {
  text-align: center;
  font: normal normal bold 24px/29px Metropolis, sans-serif;
  letter-spacing: 0px;
  color: var(--black);
  width: 336px;
  height: 24px;

  @media screen and (max-width: 480px) {
    width: unset;
    height: unset;
  }
}

.content {
  display: flex;
  flex-direction: column;
  align-items: center;
  height: fit-content;
}

.btn {
  width: 208px;
}
