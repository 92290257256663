.container {
  color: var(--black);
  letter-spacing: 0px;
  display: flex;
  gap: 16px;
  flex-direction: column;
  border-top: 2px solid var(--background-gray);
  margin-top: 36px;
  height: 104px;
  padding: 30px 26px 30px 24px;
}

.top {
  display: flex;
  justify-content: space-between;
  gap: 8px;
}

.title {
  font: normal normal bold 14px/17px Metropolis, sans-serif;
}

.details {
  font: normal normal normal 14px/17px Metropolis, sans-serif;
}
