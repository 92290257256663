.tableContainer {
  display: flex;
  width: 100%;
  height: 100%;
}

.campaignsTableContainer {
  display: flex;
  width: 100%;
  @media screen and (max-width: 1200px) {
    overflow-x: auto;
    overflow-y: hidden;
  }
}

table {
  width: 100%;
  display: flex;
  flex-direction: column;
  font-size: 13px;
  border-collapse: collapse;
  height: 100%;
}

.Table,
.campaignsTable {
  ::-webkit-scrollbar {
    width: 7px;
    border-radius: 5px;
    z-index: -1;
    height: 36px;
  }

  /* Track */
  ::-webkit-scrollbar-track {
    background: rgba(0, 0, 0, 0.15);
    border-radius: 5px;
  }

  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: rgba(0, 0, 0, 0.3);
    border-radius: 5px;
  }

  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background: rgba(0, 0, 0, 0.35);
  }
}
