.profileImage {
  display: flex;
  justify-content: center;
  align-items: center;
  text-rendering: optimizeLegibility;
  object-fit: cover;
  position: absolute;
  transform: translate(-50%, -50%);
  top: 50%;
  left: 50%;
  height: 100%;
  max-height: max-content;
  min-height: max-content;
  min-width: 100%;
}

.profilePicture {
  border-radius: 50%;
  overflow: hidden;
  position: relative;
}

.dummyImage {
  width: 40px;
  height: 40px;
  background: var(--blue);
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 16px;
  color: white;
  text-rendering: optimizeLegibility;
  & > img {
    border-radius: 50%;
  }
  & > p {
    font-weight: 600;
    line-height: 1;
    font-size: 18px;
  }
}
