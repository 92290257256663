.callout {
  padding: 15px;
  display: flex;
  gap: 10px;
  border-radius: 12px;
  font: inherit;
  font-size: 14px;
  width: auto;
}

.info {
  background-color: #f6f8fa;
  color: #9ca4ad;
}

.icon {
  flex: 0;
}
