.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.title {
  font-size: 18px;
  font-weight: 600;
}

.user {
  display: flex;
  align-items: center;
  gap: 10px;
}
.WorkspaceUsersTable {
  display: flex;
  flex-direction: column;
  height: 100%;
  > div {
    flex: 1;
    position: relative;
  }
}

.modal {
  height: fit-content !important;
  width: fit-content !important;
  @media screen and (max-width: 480px) {
    width: 350px;
    padding: 50px 20px;
    margin: auto 10px;
  }
}

.modalContent {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: stretch;
  padding: 20px;
  gap: 50px;

  .title {
    font-size: 36px;
    font-weight: 700;
  }
  .subtitle {
    font-size: 14px;
    margin: 21px 0 30px 0;
  }

  .form {
    display: flex;
    flex-direction: column;
    gap: 30px !important;

    @media screen and (max-width: 480px) {
      gap: 0 !important;
    }
  }

  @media screen and (max-width: 480px) {
    gap: 0;
    padding: 0;
  }
}

.userModal {
  display: flex;
  flex-direction: column;

  height: fit-content;
  width: fit-content;
  padding: 40px;
  gap: 30px;
  align-items: left;
  max-width: 500px;
  justify-content: space-between;
  .title {
    font-weight: 600;
    font-size: 24px;
  }
  .bottom {
    display: flex;
    align-items: center;
    flex-direction: column;
    gap: 25px;
  }
  .buttons {
    display: flex;
    gap: 15px;

    width: 100%;
  }
}

.mobileItem {
  margin-bottom: 12px;
  padding-bottom: 12px;
  border-bottom: 1px solid #d7dae0;
}

.mobileItemLast {
  border-bottom: none;
  margin-bottom: 0;
  padding-bottom: 0;
}

.listItem {
  list-style-type: none;
}

.role {
  display: flex;
  align-items: center;
  label {
    margin-right: 10%;
  }
  width: 100%;
  @media screen and (max-width: 480px) {
    flex-direction: column;
    align-items: flex-start;
    gap: 5px;
    label {
      flex: 0;
    }
  }
}
.roleContainer {
  display: flex;
  align-items: center;
  label {
    margin-right: 10%;
  }
  @media screen and (max-width: 480px) {
    flex-direction: column;
    align-items: flex-start;
    gap: 10px;
    margin-top: 30px;
    label {
      flex: 0;
    }
  }
}
