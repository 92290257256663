.switch {
  display: flex;
  align-items: center;
  gap: 16px;
  font-size: 12px;
  z-index: 95;

  @media screen and (max-width: 1200px) {
    flex-direction: column;
    align-items: flex-start;
    width: 100%;
  }
}

.align {
  display: flex;
  align-items: center;
  gap: 5px;
  > p {
    line-height: 1;
    color: var(--text-gray);
    font-size: 12px;
  }
  img {
    cursor: pointer;
  }
}
