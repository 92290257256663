.column {
  display: flex;
  flex-direction: column;
  gap: 16px;
}

.subtitle {
  font: normal normal normal 14px/26px Metropolis, sans-serif;
}

.form {
  box-sizing: border-box;
  display: flex;
  flex-flow: column wrap;
  gap: 20px;
}

.buttons {
  margin-top: 30px;
  display: flex;
  justify-content: stretch;
  flex: 1;
  gap: 10px;
  & > * {
    width: 100%;
  }
}

.logo {
  display: flex;
  height: 32px;
  margin-bottom: 30px;

  @media screen and (max-width: 480px) {
    position: static;
  }
}