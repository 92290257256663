.container {
  height: 20px;
  width: 20px;
  min-height: 20px;
  min-width: 20px;
  border: 1px solid #dcdfe4;
  border-radius: 4px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.active {
  background: #2e58fe;
  border: none;
}

.disabled {
  background: var(--divider-gray);
  border: none;
}
