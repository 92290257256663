.container {
  flex-grow: 1;
  flex-basis: 600px;
  display: flex;
  flex-direction: column;
}

.title {
  font-size: 18px;
  font-weight: 600;
  line-height: 1;
}

.header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  min-height: 32px;
}

.right {
  display: flex;
  gap: 32px;
  align-items: center;
}

.text {
  font: normal normal normal 12px/14px Metropolis, sans-serif;
  letter-spacing: 0px;
}
