.container {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 10px;
  margin: -10px;
  border-radius: 8px;
  @media screen and (max-width: 480px) {
    padding: 0;
    margin: 0;
  }
  .name {
    font-size: 16px;

    font-weight: bold;
  }
  z-index: 152;
  .left {
    margin-left: 18px;
    display: flex;
    gap: 15px;
    align-items: center;

    @media screen and (max-width: 480px) {
      flex-wrap: nowrap;
      margin-left: 0;
    }
  }
}
