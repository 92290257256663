input[type="checkbox"].checkbox {
  position: relative;
  width: 1.5em;
  height: 1.5em;
  border: 1px solid var(--text-gray);
  border-radius: 4px;
  appearance: none;
  outline: 0;
  cursor: pointer;

  &::before {
    position: absolute;
    content: "";
    display: block;
    background: url("./checkmark-white.svg");
    top: 0.9em;
    left: 19%;
    transform: translate(0.5px, -50%);
    width: 100%;
    height: 100%;
    background-repeat: no-repeat;
    background-size: 60%;
    opacity: 0;
    z-index: 20;
  }
  &:checked {
    border-color: var(--blue);
    background: var(--blue);
    &::before {
      opacity: 1;
    }
    ~ label::before {
      clip-path: none;
      display: none;
    }
  }
}
