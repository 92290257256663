.ButtonSwitch {
  display: flex;
  gap: 18px;
  align-items: center;

  & > .text {
    color: var(--text-gray);
  }

  @media screen and (max-width: 1200px) {
    width: 100%;
    justify-content: space-between;
  }
}

.btn {
  padding: 0 !important;
  position: relative;
  font-size: 14px !important;
  @media screen and (max-width: 768px) {
    width: 20%;
    &:last-child {
      width: 30%;
    }
  }
}

.notActive {
  font: normal normal normal 14px/17px Metropolis, sans-serif !important;
  letter-spacing: 0px;
  color: var(--black);
  height: 32px;
}

.active {
  position: absolute;
  z-index: 1;
  height: 2px;
  background: #2e58fe;
  top: 54.5px;
  min-width: 36px;
  width: 120%;
  @media screen and (max-width: 768px) {
    top: 45.5px;
  }
}
