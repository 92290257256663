.statusFooter {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 12px;
}

.leftSegment {
  display: flex;
  flex-direction: column;
  gap: 5px;
  letter-spacing: 0px;
  color: var(--black);
  max-width: 70%;
}

.title {
  font: normal normal 500 14px/17px Metropolis, sans-serif;
}

.text {
  font: normal normal normal 14px/20px Metropolis, sans-serif;
}

.status {
  border-radius: 16px;
  padding: 8px 13px;
  font: normal normal bold 14px/17px Metropolis, sans-serif;
  letter-spacing: 0px;

  display: flex;
  flex: 0 0 auto;

  &.success {
    background: var(--success-notification-bg);
    color: var(--success-notification-text);
  }

  &.warning {
    background: var(--bg-warning);
    color: var(--warning);
  }

  &.error {
    background: var(--bg-alert);
    color: var(--alert);
  }
}
