.dropdown {
  position: absolute;
  top: calc(100% + 1px);
  left: 0;
  background: white;
  z-index: 5000;
  display: flex;
  flex-direction: column;
  box-shadow: 0px 10px 30px #0c1d8012;
  border-radius: 8px;
  width: 100%;
  padding: 16px 6px;
}
