.menuItem {
  font-size: 14px;
  display: flex;
  justify-content: center;
  align-content: center;
  align-items: center;
  background-color: #fff;
  gap: 10px;
  color: var(--text-black);
  font-weight: 400;
  line-height: 0;
  position: relative;
  margin-top: auto;
  > img {
    line-height: 0 !important;
  }
}

.active {
  position: absolute;
  top: -10px;
  bottom: 0;
  left: -12px;
  right: 12px;
  background: rgb(19 43 177 / 12%);
  border-radius: 4px;
  height: 40px;
}

.activeText {
  color: var(--blue);
  font-weight: 600;
}

.text {
  flex-grow: 2;
  line-height: 1;
}

.errorIcon {
  position: absolute;
  right: 24px;
  top: 50%;
  transform: translateY(-50%);
}

.link {
  text-decoration: none !important;
}
