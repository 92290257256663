@import "../../index.scss";

.integrationHeader {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.leftSegment {
  display: flex;
  gap: 12px;
  align-items: center;
}

.text {
  display: flex;
  flex-direction: column;
}

.title {
  color: var(--black);
  font: normal normal bold 14px/17px Metropolis, sans-serif;
  letter-spacing: 0px;
}
.subtitle {
  color: var(--icon-gray);
  font: normal normal normal 12px/14px Metropolis, sans-serif;
  letter-spacing: 0px;
}
