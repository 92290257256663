/* Hides the checkbox */
input#toggle {
  max-height: 0;
  max-width: 0;
  opacity: 0;
  display: none;
}

.wrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 15px;
}

.label.checked {
  color: var(--blue);
}

.label {
  font: normal normal normal 14px/14px Metropolis, sans-serif;
  letter-spacing: 0px;
}

/* <label> style to look/animate like ios toggle button */
input#toggle + label {
  min-width: 50px;
  display: block;
  position: relative;
  box-shadow: inset 0 0 0px 1px #d5d5d5;
  text-indent: -5000px;
  height: 30px;
  width: 50px;
  border-radius: 15px;
  cursor: pointer;
  transition: 0.25s ease-in-out;
}

input#toggle + label:before {
  content: "";
  position: absolute;
  display: block;
  height: 30px;
  width: 30px;
  top: 0;
  left: 0;
  border-radius: 15px;
  background: transparent linear-gradient(295deg, var(--blue) 0%, var(--light-blue) 100%) 0% 0% no-repeat padding-box;
  -moz-transition: 0.25s ease-in-out;
  -webkit-transition: 0.25s ease-in-out;
  transition: 0.22s ease-in-out;
  opacity: 0;
}

input#toggle + label:after {
  content: "";
  position: absolute;
  display: block;
  height: 30px;
  width: 30px;
  top: 0;
  left: 0px;
  border-radius: 15px;
  background: white;
  box-shadow: inset 0 0 0 1px rgba(0, 0, 0, 0.2), 0 2px 4px rgba(0, 0, 0, 0.2);
  -moz-transition: 0.25s ease-in-out;
  -webkit-transition: 0.25s ease-in-out;
  transition: 0.25s ease-in-out;
}

/* Switch background if checked */
input#toggle:checked + label:before {
  width: 50px;
  opacity: 1;
  background: transparent linear-gradient(295deg, var(--blue) 0%, var(--light-blue) 100%) 0% 0% no-repeat padding-box;
}

input#toggle:checked + label:after {
  left: 20px;
  box-shadow: inset 0 0 0 1px var(--blue), 0 2px 4px rgba(0, 0, 0, 0.2);
}
