.wrapper {
  position: relative;
  height: fit-content;
  line-height: 1;

  display: flex;

  align-items: center;
  > img {
    cursor: pointer;
    z-index: 20 !important;
  }
}

.tableDropdown {
  height: fit-content;

  padding: 10px;
  background-color: white;
  width: 200px;
  border-radius: 8px;
  display: flex;

  flex-direction: column;
  gap: 10px;
  position: absolute;
  right: 20px;
  top: 20px;
  box-shadow: 0px 3px 10px #03081c1c;
}

.z {
  z-index: 31;
}

.menuItem {
  display: flex;
  justify-content: flex-start;
  cursor: pointer;
  font-size: 13px;
  color: var(--text-black);
  gap: 5px;
  align-items: center;
  &:not(:first-child) {
    padding-top: 10px;
    border-top: 1px solid var(--input-outline);
  }
}
