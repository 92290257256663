.navigation {
  width: 100vw;
  height: 80px;
  background-color: white;
  padding: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  bottom: 0;
  z-index: 100;
  @media (max-width: 480px) {
    border-top: 1px solid rgb(215, 218, 224);
  }
}

.list {
  margin: 0;
  padding: 0;
  list-style-type: none;
  display: flex;
  align-items: center;
  height: 100%;
  justify-content: center;
  gap: 50px;
  @media (max-width: 480px) {
    gap: 28px;
  }
}

.hiddenItem {
  opacity: 0.25;
}

.link {
  display: flex;
  flex-direction: column;
  align-items: center;
  color: var(--text-black);
  gap: 8px;
  @media (max-width: 480px) {
    width: 65px;
  }
}

.text {
  font-family: Metropolis, sans-serif;
  font-weight: normal;
  font-size: 11px;
  margin: 0;
  line-height: 1;
  @media (max-width: 480px) {
    letter-spacing: 0px;
  }
}

.active {
  color: var(--blue);
  @media (max-width: 480px) {
    font-weight: bold;
  }
}

.workspaceText {
  @media (max-width: 480px) {
    letter-spacing: -0.11px;
  }
}

.workspaceActive {
  @media (max-width: 480px) {
    font: normal normal bold 11px/26px Metropolis, sans-serif;
    color: var(--blue);
  }
}

.icon {
  @media (max-width: 480px) {
    height: 24px;
    width: 24px;
  }
}

.iconsContainer {
  position: relative;
}

.errorIcon {
  position: absolute;
  right: -8px;
  top: -3px;
}
