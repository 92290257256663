.notification {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: fit-content;

  position: fixed;
  bottom: 2%;
  right: 2%;

  cursor: pointer;
  gap: 10px;
  color: #ffffff;

  font-weight: 500;
  border-radius: 4px;
  padding: 12px 16px;
  z-index: 999999;

  @media screen and (max-width: 480px) {
    max-width: 370px;
  }
}

.success {
  background: var(--success);
}

.warning {
  background: var(--warning);
}

.error {
  background: var(--alert);
}
.info {
  background: var(--light-blue);
}
