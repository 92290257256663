.button {
  border: none;
  box-shadow: none;
  text-align: center;
  border-radius: 30px;
  cursor: pointer;
  width: fit-content;
  align-items: center;
  transition: all 0.2s ease-out;
  padding: 0 24px;
}

.big {
  font: normal normal bold 14px/17px Metropolis, sans-serif;
  height: 54px;
}

.medium {
  font: normal normal bold 14px/17px Metropolis, sans-serif;
  height: 42px;
}

.small {
  height: 32px;
  padding: 0 16px;
  font: normal normal bold 12px/14px Metropolis, sans-serif;
}

.primary {
  background: var(--blue);
  color: white;
  text-rendering: optimizeLegibility;

  &:hover {
    background: var(--blue-lighter);
  }
}

.secondary {
  background: white;
  color: #252e3e;
  border: 1px solid var(--input-outline);
  &:hover {
    border: 1px solid var(--gray-darker);
  }
}

.terciary {
  font: normal normal bold 14px/17px Metropolis, sans-serif;
  letter-spacing: 0px;
  color: var(--link-color);
  background: transparent;
  &:hover {
    color: #2d49e3;
  }
}

.danger {
  background: var(--red);
  color: white;
  text-rendering: optimizeLegibility;
  &:hover {
    background: var(--red-hover);
  }
}

.text {
  background: transparent;
  font-weight: 600;
  border: 0;
}

.fullWidth {
  width: 100% !important;
}

.content {
  display: flex;
  gap: 10px;
  justify-content: space-around;
  align-items: center;
  padding: 0;
  line-height: 1;
}

.hiddenContent {
  * {
    visibility: hidden !important;
    -webkit-user-select: none;
    user-select: none;
  }
  .spinnerContainer {
    visibility: visible !important;
    * {
      visibility: visible !important;
    }
  }
}

.innerContainer {
  display: flex;
  gap: 10px;
  justify-content: space-around;
  align-items: center;
  padding: 0;
  line-height: 1;
  white-space: nowrap;
  > span {
    line-height: 1;
    white-space: nowrap;
  }
}

.spinnerContainer {
  position: absolute;
}

.iconOnly {
  border-radius: 400px;
  border: 1px solid var(--input-outline);
  padding: 10px;
  width: 10px;
  box-sizing: content-box;
  align-items: center;
  height: 10px;
}

.disabled {
  background: var(--divider-gray);
  color: #96a1b1;
  box-shadow: none;
  &:hover {
    @extend .disabled;
    cursor: initial;
  }
}
