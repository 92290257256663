.pageContainer {
  display: flex;
  flex-direction: column;
  gap: 24px;
}

.title {
  justify-self: flex-start;
  width: 100%;
  margin-left: 8px;
}
