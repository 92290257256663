.text {
  font: normal normal normal 12px/14px Metropolis, sans-serif;
  letter-spacing: 0px;
  color: #9ca4ad;
}

.selectText {
  font: normal normal normal 14px/26px Metropolis, sans-serif;
  letter-spacing: -0.14px;
  color: var(--black);
}

.container {
  display: flex;
  align-items: center;
  gap: 13px;
}

.selectContainer {
  display: flex;
  border: 1px solid var(--divider-gray);
  border-radius: 8px;
  padding: 8px 16px;
  width: 150px;
  align-items: center;
  cursor: pointer;
  position: relative;
  justify-content: space-between;
  height: 40px;
}

.wrapper {
  display: flex;
  gap: 14px;
}

.overlay {
  position: fixed;
  left: 0;
  top: 0;
  bottom: 0;
  right: 0;
  z-index: 98;
  height: 100vh;
  width: 100vw;
  cursor: default;
}
