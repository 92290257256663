.spinner {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 2;
}

.innerSpinner {
  width: 30px;
  height: 30px;
  border-radius: 50%;
  border: 2px solid var(--blue);
  border-bottom-color: transparent !important;
  border-left-color: transparent !important;
  animation: spin 0.6s cubic-bezier(0.445, 0.05, 0.55, 0.95) infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0);
  }

  100% {
    transform: rotate(360deg);
  }
}
