.container {
  margin-bottom: 48px;
}

.grid {
  display: grid;
  grid-template-rows: repeat(3, 1fr);
  grid-column-gap: 20px;
  column-gap: 20px;
  grid-row-gap: 10px;
  row-gap: 10px;
  grid-auto-flow: column;
  @media screen and (max-width: 1024px) {
    display: flex;
    flex-direction: column;
  }
  @media screen and (max-width: 480px) {
    gap: 20px;
  }
}

.subtitle {
  font: normal normal bold 14px/17px Metropolis, sans-serif;
  letter-spacing: 0px;
  color: var(--black);
}

.row {
  display: flex;
  align-items: center;
  justify-content: space-between;
  border: 1px solid #ebeef0;
  border-radius: 8px;
  padding: 0 16px;
  height: 60px;
  @media screen and (max-width: 480px) {
    width: unset;
    border: none;
    padding: 0;
    height: 36px;
  }
}

.left {
  display: flex;
  align-items: center;
  gap: 12px;
  flex: 1;
}

.right {
  display: flex;
  gap: 12px;
  align-items: center;
  min-width: 40%;
  justify-content: space-between;
  @media screen and (max-width: 480px) {
    width: unset;
    flex-direction: column-reverse;
    gap: 4px;
    align-items: flex-end;
  }
}

.change {
  display: flex;
  align-items: center;
  height: 24px;
  border-radius: 3px;
  gap: 5px;
  padding: 5px 6px 5px 5px;
  p {
    letter-spacing: 0px;
    font: normal normal normal 14px/17px Metropolis, sans-serif;
  }
  @media screen and (max-width: 480px) {
    height: auto;
    width: auto;
    padding: 4px 3px 4px 4px;
    justify-content: center;
    background-color: transparent !important;
    p {
      font: normal normal bold 12px/14px Metropolis, sans-serif;
    }
  }
}

.increase {
  background: #e5f7ef 0% 0% no-repeat padding-box;
  p {
    color: #009d25;
  }
}
.decrease {
  background: rgba(#ff002e, 0.1) 0% 0% no-repeat padding-box;
  p {
    color: #ff002e;
  }
}

.neutral {
  background: rgba(#647587, 0.1) 0% 0% no-repeat padding-box;
  p {
    color: #647587;
  }
}

.title {
  font: normal normal bold 14px/17px Metropolis, sans-serif;
  letter-spacing: 0px;
  color: var(--black);
  line-height: 1;
  @media screen and (max-width: 480px) {
    align-self: flex-end;
  }
}
