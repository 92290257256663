.container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100%;
}

.placeholder {
  display: flex;
  max-height: 100%;
  position: relative;
  border-radius: 4px;
  cursor: progress;
  background-color: #e9e9e9;
  height: 100%;
  width: 100%;
  background-image: linear-gradient(to right, #e9e9e9 0%, rgba(0, 0, 0, 0.05) 20%, #e9e9e9 40%, #e9e9e9 100%);
  background-repeat: no-repeat;
  background-size: 450px 400px;
  animation: shimmer 1.5s linear infinite;
  overflow-y: hidden;

  &.dark {
    background-color: #4355c1;
    background-image: linear-gradient(to right, #4355c1 0%, rgba(0, 0, 0, 0.05) 20%, #4355c1 40%, #4355c1 100%);
  }
}

.input {
  height: 50px !important;
  border-radius: 12px;
  border: 1px solid var(--input-outline);
}

@keyframes shimmer {
  0% {
    background-position: -450px 0;
  }
  100% {
    background-position: 450px 0;
  }
}
.round {
  border-radius: 100%;
}

@keyframes loading {
  100% {
    transform: translateX(100%);
  }
}
