.main {
  height: 100vh;
  overflow: hidden;
  flex-basis: 0;
  flex-grow: 999;
  min-width: 60%;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  position: relative;
  @media screen and (max-width: 480px) {
    height: unset;
  }
}
