@import "../../index.scss";

.timezone {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.select {
  & > * {
    color: var(--text-black) !important;
    width: 100%;
    box-sizing: border-box !important;
    font: inherit;
    font-size: 14px;
    &::placeholder {
      color: var(--text-gray);
    }
    &:focus {
      outline: none;
      border: 1px solid var(--blue);
    }
  }
}

.inputWrapper {
  position: relative;
}

.icon {
  position: absolute;
  left: 24px;
  top: 50%;
  display: flex;
  align-items: center;
  transform: translate(0, -50%);
  z-index: 2;
}

.currencyWrapper {
  display: flex;
  flex-direction: column;
  gap: 10px;
  justify-content: flex-end;
}

.error {
  margin-top: 10px;
}
label {
  font: inherit;
  font-size: 14px;
  font-weight: 600;
}

.currencyNoInput {
  @extend .basic-input;
  padding: 14px 10px 14px 65px;
  height: 50px;
  font: inherit;
  font-size: 14px;
}

.workspaceForm {
  height: 100%;
  overflow-y: auto;
  overflow-x: hidden;
  :global(.select__indicator-separator) {
    display: none !important;
  }

  :global(.select__value-container) {
    padding: 10.5px 65px !important;
    height: 50px;
  }

  :global(.select__menu) {
    z-index: 999 !important;
  }
}

.sideBySideAlign {
  gap: 0 !important;
  overflow-y: unset !important;
  .inputsContainer {
    gap: 24px;
  }
  // this is to target html elements in nested components as well
  > div > div > div {
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    height: 50px;
    > div {
      max-width: 460px;
      flex: 3;
    }
  }

  > .inputsContainer {
    overflow: unset;
  }

  .btnContainer {
    display: flex;
    width: 100%;
    justify-content: center;
    button {
      width: 134px;
      padding: 0 10px;
    }
  }
}

.inputsContainer {
  display: flex;
  flex-direction: column;
  gap: 16px;
  overflow-y: auto;
  height: 100%;
  @media (max-width: 480px) {
    overflow-y: unset;
  }
}

.calloutContainer {
  max-width: 400 !important;
}
