.header {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  height: fit-content;
  padding: 24px 48px 24px 48px;
  top: 0;
  width: 100%;
  background-color: rgb(243 244 246 / 80%);
  backdrop-filter: blur(4px);
  z-index: 2;
  position: absolute;

  @media screen and (max-width: 480px) {
    padding: 16px 20px 24px 24px;
    background-color: rgba(#f4f5f7, 0.9);
    position: fixed;
  }
}

.headerSwitch {
  * {
    visibility: hidden;
  }
  z-index: 154;
  background: transparent;
}

.workspacePath {
  z-index: 0 !important;
}
