.title {
  font: normal normal bold 20px/24px Metropolis, sans-serif;
  letter-spacing: 0px;
  @media screen and (max-width: 480px) {
    width: unset;
  }
}

.middle {
  display: flex;
  gap: 10px;
  align-items: center;

  @media screen and (max-width: 480px) {
    gap: 20px;
  }
}

.right {
  display: flex;
  flex-wrap: nowrap;
  flex-direction: row;
  gap: 30px;
  z-index: 152;
}

.mobileHeaderContent {
  display: flex;
  flex-direction: column;
  gap: 36px;
  flex: 1;
  .top {
    display: flex;
    flex: 1;
    justify-content: space-between;
    gap: 20px;
  }
}
