.TariffUsage {
  display: flex;
  gap: 10px;
  align-items: center;
  justify-content: flex-end;
  height: fit-content;
  position: relative;
}

.text {
  margin: 0;
  font-size: 13px;
  flex: 0;
}

.orangeText {
  color: var(--warning);
}

.redText {
  color: var(--red);
}

.textUnlimited {
  margin: 0;
  font-size: 13px;
  width: 90%;
  position: relative;
}

.tariffBarWrapper {
  width: 60%;
}
