.container {
  background: var(--blue-gradient);
  box-shadow: 0px 10px 15px #00000014;
  backdrop-filter: blur(10px);

  position: fixed;
  max-height: 100%;
  overflow-y: hidden !important;
  max-width: 100%;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  will-change: opacity;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  align-content: center;
  text-align: center;
  z-index: 153;

  .wrapper {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    align-content: center;
    text-align: center;
    gap: 40px;
    height: 100%;
    width: 100%;
    overflow-y: hidden !important;

    @media screen and (max-width: 480px) {
      position: relative;
      top: 124px;
      justify-content: unset;
      gap: 20px;
      margin-bottom: 200px;
    }
  }

  .workspaceSwitch {
    background: transparent;
    color: var(--blue);
    box-shadow: none;
    .arrow {
      width: 8px;

      &.active {
        transform: rotate(180deg);
      }
    }
    & > * {
      gap: 20px;
    }
  }

  .button {
    grid-column: 2;
    justify-self: center;
    align-self: center;
  }

  .title {
    font: normal normal bold 72px/86px Metropolis, sans-serif;
    letter-spacing: 0px;
    color: #ffffff;
    opacity: 1;

    @media screen and (max-width: 480px) {
      width: 100%;
      font-size: 36px;
      opacity: 1;
    }
  }

  .workspaceCards {
    display: flex;
    gap: 30px;
    flex-wrap: wrap;

    max-width: 80%;
    margin: 0 auto;
    max-height: 50%;
    overflow-y: auto;
    padding: 30px;
    justify-content: center;

    @media screen and (max-width: 480px) {
      max-height: unset;
      gap: 16px;
      max-width: unset;
      display: grid;
      width: 100%;
    }
  }
}

.bottomText {
  color: white;
  text-rendering: optimizeLegibility;
  z-index: 200;
  font-size: 14px;
  & a {
    color: white;
    text-rendering: optimizeLegibility;
    text-decoration: underline;
    font-weight: 400;
  }

  @media screen and (max-width: 480px) {
    display: flex;
    flex-direction: column-reverse;
    padding-bottom: 24px;
  }
}

.bottomTextContainer {
  margin-bottom: 50px;

  @media screen and (max-width: 480px) {
    background-color: #0a26c0;
    margin-bottom: 0px;
    position: fixed;
    bottom: 0;
    width: 100vw;
    height: 70px;
  }
}

.newWorkspace {
  position: absolute;
  right: 112px;
  bottom: 28px;
  background-color: #ffffff33;
  color: #fff;
  border-radius: 8px;
  padding: 16px 14px;
  height: 60px;
  width: 200px;
  cursor: pointer;

  @media screen and (max-width: 1200px) {
    right: 26px;
  }

  @media screen and (max-width: 480px) {
    bottom: 65px;
    right: 112px;
    position: static;
    gap: 32px;
    width: 90%;
    justify-content: space-between;
    align-self: center;
    background-color: rgb(255 255 255 / 25%);
  }

  display: flex;
  justify-content: space-between;
  align-items: center;

  .text {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .name {
    font-size: 16px;
    font-weight: 500;
  }
  .price {
    bottom: 25%;
    font-weight: lighter;
    font-size: 14px;
  }

  .plus {
    font-size: 30px;
  }
}

.logout {
  font-size: 16px;
  font-weight: 500;
  position: absolute;
  left: 56px;
  bottom: 50px;
  color: #fff;
  cursor: pointer;

  @media screen and (max-width: 480px) {
    left: 22px;
    bottom: 90px;
  }
}
