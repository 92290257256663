.pageContainer {
  display: flex;
  flex-direction: column;
  gap: 24px;
}

.container {
  @media (max-width: 1200px) {
    max-height: unset;
    margin-bottom: 50px;
  }

  > div:first-of-type {
    @media (max-width: 768px) {
      height: unset;
      min-height: unset;
      align-items: flex-start;
      padding: 24px 20px 14px 30px !important;
    }
    @media (max-width: 480px) {
      padding: 24px 20px 14px 20px !important;
    }
    > div:last-of-type {
      @media (max-width: 480px) {
        padding: 0 !important;
      }
    }
  }
}
.noDataContainer {
  max-height: calc(100% - 96px);
  border-radius: 8px;
  @media (max-width: 1200px) {
    max-height: unset;
    justify-content: center;
  }
}

.topSegment {
  display: flex;
  justify-content: space-between;
  align-items: center;
  z-index: 3000;
  & > :first-child {
    font-weight: 600;
    font-size: 18px;
  }
  gap: 20px;
  @media screen and (max-width: 768px) {
    align-items: flex-start;
    justify-content: center;
    flex-direction: column;
  }
}

.download {
  font-size: 14px;
  font-weight: bold;
  cursor: not-allowed;
  color: var(--black);
  @media screen and (max-width: 480px) {
    align-self: center;
  }
}

.spin {
  animation: spin 2s infinite linear;
}

@keyframes spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(359deg);
  }
}

.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  @media screen and (max-width: 1200px) {
    width: 100%;
  }
}

.dataSegment {
  position: relative;
  flex: 1;
  height: 100%;
  @media screen and (max-width: 1200px) {
    position: static;
  }
}

.dataContainer {
  left: 0;
  right: 0;
  height: 100%;
  position: absolute;
  overflow: auto;
  @media screen and (max-width: 1200px) {
    overflow: unset;
    position: relative;
  }
  @media screen and (max-width: 480px) {
    position: static;
    overflow: unset;
  }
}

.controlsContainer {
  display: flex;
  align-items: center;
  gap: 24px;
}

.title {
  font: normal normal bold 20px/24px Metropolis, sans-serif;
  letter-spacing: 0px;
  @media screen and (max-width: 480px) {
    justify-self: flex-start;
    width: 100%;
    margin-left: 8px;
  }
}
