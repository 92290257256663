.codeBox {
  background-color: rgba(244, 245, 247, 0.5);
  padding: 18px 32px;
  margin: 12px -32px 30px -32px;
  color: var(--text-gray);
  grid-template-rows: auto;
  grid-template-columns: auto;
  display: grid;
  white-space: pre-line;
  font-size: 12px;
  cursor: pointer;
  border-radius: 4px;

  .code {
    grid-column: 1;
    grid-row: 1;
    overflow-x: auto;
  }

  .copyButton {
    align-self: end;
    grid-column: 1;
    grid-row: 1;
    justify-self: end;
  }
}
