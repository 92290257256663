.select {
  & > * {
    color: var(--text-black) !important;
    width: 100%;
    box-sizing: border-box !important;
    border: 1px solid var(--input-outline) !important;
    border-radius: 8px !important;
    font: inherit;
    font-size: 14px;
    &::placeholder {
      color: var(--text-gray);
    }

    &:focus {
      outline: none;
      border: 1px solid var(--blue);
    }
  }
}

.selectWrapper {
  display: flex;
  flex-direction: column;
  font: inherit;
  font-size: 14px;
}

.label {
  font: inherit;
  font-size: 14px;
  font-weight: 600;
}

.selectSmall {
  height: 30px;
  min-width: 210px;
  :global(.select__control) {
    height: 100%;
    min-height: unset;
    border-radius: 8px;
  }
  :global(.select__value-container) {
    height: 100%;
    padding: 0;
  }
  :global(.select__indicators) {
    height: 100%;
  }
  :global(.select__single-value) {
    margin: 15px 9px;
    font: normal normal 500 12px/14px Metropolis, sans-serif;
    letter-spacing: 0px;
  }
}

.iconContainer {
  margin-right: 16px;
  display: flex;
}
