.confirmationModal {
  display: flex;
  flex-direction: column;

  height: fit-content;
  gap: 21px;
  max-width: 500px;
  width: 500px;
  justify-content: space-between;
  align-items: center;
  .title {
    font: normal normal bold 24px/29px Metropolis, sans-serif;
  }
  .bottom {
    display: flex;
    align-items: center;
    flex-direction: column;
    gap: 32px;
    font: normal normal normal 14px/26px Metropolis, sans-serif;
  }
  .buttons {
    display: flex;
    gap: 16px;
    width: 100%;
  }
}
