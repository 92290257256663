.card {
  width: 200px;
  height: 120px;
  border: 1px solid rgba(255, 255, 255, 0.25);
  border-radius: 15px;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  color: white;
  text-rendering: optimizeLegibility;
  justify-self: center;
  cursor: pointer;
  position: relative;
  transition: 0.3s ease-out;

  @media screen and (max-width: 480px) {
    font: normal normal 500 16px/21px Metropolis, sans-serif;
    height: 104px;
    width: 100%;
    max-width: 200px;
  }

  .content {
    display: flex;
    flex-direction: column;
    align-items: center;
    @media screen and (max-width: 480px) {
      font: normal normal 500 16px/21px Metropolis, sans-serif;
    }

    .name {
      margin-bottom: 6px;
      max-width: 180px;
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap;
      font: normal normal bold 20px/24px Metropolis, sans-serif;
      letter-spacing: 0px;
      color: #ffffff;
      opacity: 1;

      @media screen and (max-width: 480px) {
        max-width: 150px;
        white-space: unset;
        font: normal normal 500 16px/21px Metropolis, sans-serif;
      }
    }
  }

  .border {
    border: 1px solid white;
    width: 42px;
    opacity: 0.3;
    position: absolute;
    bottom: 15%;
  }
}

.notCurrentWorkspace {
  &:hover {
    background: rgb(255 255 255 / 10%) 0% 0% no-repeat padding-box;
    border: 1px solid #ffffff;
  }
}

.currentWorkspace {
  border: 2px solid rgba(255, 255, 255, 1);
}

.expiredWorkspace {
  background: #ffffff33 0% 0% no-repeat padding-box;
  border-radius: 4px;
  opacity: 1;
  padding: 3px 8px;
  height: 24px;
  position: absolute;
  bottom: 16px;
  font: normal normal normal 14px/17px Metropolis, sans-serif;
  letter-spacing: 0px;
  color: #ffffff;
  display: flex;
  align-items: center;
  justify-content: center;
}

.errorIcon {
  position: absolute;
  right: 12.5px;
  top: 15px;
}
