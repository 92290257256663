.tooltip {
  background: white;
  box-shadow: 0px 15px 20px #362c9026;
  padding: 15px;
  display: flex;
  align-items: center;
  justify-content: center;
  align-content: center;
  backdrop-filter: blur(1px);
  border-radius: 5px;
  gap: 20px;
  width: fit-content;
  position: relative;
}

.text {
  display: flex;
  flex-direction: column;
  gap: 0;
  .title {
    font-size: 16px;
    font-weight: 600;
  }

  .subtitle {
    display: flex;
    align-items: center;
    flex-direction: row;
    color: var(--text-gray);
    font-size: 14px;
  }
}

.triangle {
  position: absolute;
  bottom: -5px;
  transform: rotate(45deg);
  width: 20px;
  height: 20px;
  background-color: white;
}

.point {
  position: absolute;
  border: 5px solid #ffffff;
  width: 13px;
  height: 13px;
  bottom: -14.14px;
  transform: translate(-50%, 50%);
  border-radius: 50%;
  left: 50%;
}
