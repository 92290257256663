.headerContainer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 16px;
  @media screen and (max-width: 768px) {
    flex-direction: column;
    align-items: stretch;
  }
}

.detailCard {
  height: 80px;
  justify-content: space-between;
  padding: 6px 0px;
  width: 25%;
  display: flex;
  flex-direction: column;
  gap: 13px;
  border-right: 1px solid #ebeef0;
  &:last-child {
    border-right: none;
  }
}

.cardTitle {
  text-align: left;
  font: normal normal normal 14px/26px Metropolis, sans-serif;
  letter-spacing: -0.14px;
  color: #647587;
}

.contentContainer {
  display: flex;
  gap: 24px;
  @media screen and (max-width: 480px) {
    flex-direction: column;
  }
}

.value {
  font: normal normal bold 24px/29px Metropolis, sans-serif;
  letter-spacing: 0px;
  color: var(--black);
}

.dataContainer {
  display: flex;
  gap: 13px;
  align-items: center;
}

.change {
  width: 60px;
  height: 26px;
  border-radius: 4px;
  display: flex;
  align-items: center;
  p {
    font: normal normal bold 14px/17px Metropolis, sans-serif;
    letter-spacing: 0px;
  }
}

.increase {
  background: #e5f7ef 0% 0% no-repeat padding-box;
  p {
    color: #009d25;
  }
}

.tabletContainer {
  > div:first-of-type {
    height: unset;
    min-height: unset;
    align-items: flex-start;
    padding: 24px 30px 14px 30px !important;
    @media screen and (max-width: 480px) {
      padding: 24px 20px 14px 20px !important;
    }
  }
  > div:last-of-type {
    padding: 0 !important;
  }
}

.bigContainer {
  @media screen and (max-width: 1200px) {
    > div:last-of-type {
      padding: 0 !important;
    }
  }
}

.tabletContentContainer {
  display: grid;
  grid-template-columns: repeat(2, 50%);
  .detailCard {
    width: unset;
    border-right: none;
    height: unset;
    @media screen and (max-width: 1200px) {
      padding: 24px 0 16px 30px;
    }
    @media screen and (max-width: 480px) {
      padding: 24px 0 16px 20px;
    }
    &:nth-child(even) {
      border-left: 1px solid #ebeef0;
    }
    &:nth-child(-n + 2) {
      border-bottom: 1px solid #ebeef0;
    }
  }
}

.titleContainer {
  display: flex;
  gap: 4px;
}

.attributionSwitchContainer {
  > div {
    gap: 30px !important;
    @media screen and (max-width: 480px) {
      gap: 16px !important;
    }
  }
  @media screen and (max-width: 480px) {
    padding: 0 10px;
  }
}

.spacer {
  width: 195px;
}

.tabletViewTop {
  display: flex;
  gap: 64px;
  align-items: center;
  justify-content: space-between;
}
