.modal {
  height: fit-content !important;
  width: fit-content !important;
}

.modalContent {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: stretch;
  gap: 40px;

  .title {
    font-size: 24px;
    font-weight: 600;
  }
  .subtitle {
    margin-top: 5px;
    font-size: 14px;
  }

  .form {
    display: flex;
    flex-direction: column;
    gap: 24px !important;
    > button {
      margin-top: 24px;
    }
  }
}

.roles {
  display: flex;
  justify-content: space-between;
  width: 70%;
}
