.content {
  display: flex;
  justify-content: space-between;
}

.paymentEmpty,
.paymentError {
  background: #d31f4b 0% 0% no-repeat padding-box;
  a {
    text-decoration: underline;
  }
  * {
    color: white;
  }
}

.paymentPending {
  background: #2a43ce 0% 0% no-repeat padding-box;
  color: white;
  a {
    color: white;
  }
}

.paymentUnapproved {
  background: #2a43ce 0% 0% no-repeat padding-box;
  color: white;
  a {
    color: white;
  }
}

.noDataInPeriodAndDataOutside,
.noData {
  background: #e0e3e7;
}

.noDataAndYoungerThanWeek {
  background: transparent linear-gradient(80deg, #3856ff 0%, #7d46ff 100%) 0% 0% no-repeat padding-box;
  color: white;
  a {
    color: white;
  }
}

.left {
  display: flex;
  flex-direction: column;
  gap: 24px;
  padding: 12px 0;
  width: 70%;
  @media screen and (max-width: 480px) {
    padding: 0 12px 4px 0;
    width: unset;
  }
}

.right {
  display: flex;
  align-items: center;
}

.title {
  font: normal normal bold 16px/19px Metropolis, sans-serif;
  letter-spacing: 0px;
}

.textContainer {
  display: flex;
  flex-direction: column;
  .text {
    font: normal normal normal 13px/20px Metropolis, sans-serif;
  }
  .bold {
    font: normal normal bold 14px/26px Metropolis, sans-serif;
  }
  a {
    font: normal normal bold 13px/20px Metropolis, sans-serif;
  }
}
