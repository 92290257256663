.modal {
  display: flex;
  background-color: #fff;
  padding: 58px 45px 48px 45px;
  border-radius: 15px;
  max-width: 90%;
  overflow-y: auto;
  height: min(90%, 850px);
  position: fixed;
  margin: auto;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 200;
  justify-content: center;
  will-change: opacity !important;

  p {
    text-align: center;
  }

  @media (max-width: 480px) {
    overflow: hidden;
    padding: 50px 20px;
    max-width: 350px;
  }
}

.overlay {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: var(--blue-gradient);

  box-shadow: 0px 10px 15px #00000014;
  will-change: opacity !important;
  z-index: 198;

  backdrop-filter: blur(3px);

  &.gray {
    background: rgba($color: #000000, $alpha: 0.7);
  }
  &.blue {
    background: var(--blue-gradient);
  }
  &.blueblur {
    background: var(--blue-gradient);
    filter: opacity(0.65);
  }
  &.grayblur {
    background: #00072f;
    filter: brightness(1) opacity(0.65);
  }
}

.blur {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  z-index: 199;
}

.content {
  display: relative;
}

.close {
  position: absolute;
  right: min(2%, 20px);
  top: min(2%, 20px);
  cursor: pointer;
}

.header {
  margin-bottom: min(20%, 50px);
}

.fitContent {
  height: fit-content;
  width: fit-content;
}

.bottomText {
  position: absolute;
  bottom: 0;
}
