.userMenuWrapper {
  position: relative;
  height: fit-content;
  line-height: 1;
  z-index: 120;
  display: flex;
  align-items: center;
  > img {
    cursor: pointer;
    @media screen and (max-width: 480px) {
      visibility: hidden;
    }
  }

  @media screen and (max-width: 480px) {
    height: 40px;
    width: 40px;
    position: fixed;
    right: 16px;
  }
}

.userMenu {
  height: fit-content;
  padding: 9px 6px;
  box-shadow: 0px 10px 15px #00000014;
  background-color: white;
  width: 200px;
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  position: absolute;
  right: -15px;
  top: 40px;
  &::before {
    content: "";
    background-color: white;
    width: 10px;
    height: 10px;
    position: absolute;
    top: -5px;
    right: 18px;
    transform: rotate(45deg) !important;
    @media screen and (max-width: 480px) {
      display: none;
    }
  }
  @media screen and (max-width: 480px) {
    right: 0;
    top: 50px;
    width: 358px;
  }
}

.text {
  font-size: 14px;
  line-height: 1;
}

.menuItem {
  display: flex;
  cursor: pointer;
  gap: 10px;
  padding: 10px;
  width: 100%;
  align-items: center;
  border-radius: 4px;
  &:hover {
    background-color: #f7f9fb;
  }
}
