.section {
  position: relative;
  flex: 1;
  height: 100%;
}

.container {
  height: 100%;
  tbody {
    height: calc(100% - 2px) !important;
  }
  @media screen and (max-width: 480px) {
    position: static;
    margin: 0 -20px;
  }
}
