.campaignContainer {
  padding-top: 12px;
  padding-bottom: 12px;
  border-bottom: 1px solid #d7dae0;
}

.lastCampaignContainer {
  border-bottom: none;
  padding-bottom: 36px;
}

.campaignMobile {
  padding: 12px 12px;
  display: flex;
  flex-direction: column;
  gap: 3px;
  font-weight: 700;
}

.expandedCampaignMobile {
  @extend .campaignMobile;
  @extend .itemRow;
  flex-direction: row;
  border-bottom: 1px solid #d7dae0;
}

.campaignName {
  max-width: 90px;
}

.chevronContainer {
  display: flex;
  width: 100%;
  justify-content: space-between;
  > img {
    align-self: center !important;
    position: relative !important;
    top: 0px !important;
  }
}

.expandedChevronContainer {
  display: flex;
  width: 100%;
  justify-content: space-between;
  > img {
    align-self: center !important;
    position: relative !important;
    top: -3px !important;
  }
}

.stickyName {
  position: sticky;
  top: -20px;
  background: white;
  z-index: 3;
  min-height: 75px;
  min-height: 60px;
  height: 60px;
  justify-content: center;
}

.nameContainer {
  min-width: 110px;
  display: flex;
  gap: 5px;
  align-items: center;
}

.name {
  line-height: 1.5;
  display: flex;
  align-items: center;
  gap: 5px;
}

.itemRow {
  display: flex;
  align-items: center;
  gap: 5px;
}

.expandedItemRow {
  @extend .itemRow;
}

.iconContainer {
  display: flex;
  > img {
    width: 14px;
    height: 14px;
  }
}

.breakeven {
  color: var(--text-black);
  font-weight: 700;
}

.profit {
  font-weight: 700;
  color: var(--success);
}

.loss {
  font-weight: 700;
  color: var(--alert);
}
