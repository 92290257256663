.container {
  display: flex;
  flex-direction: column;
  height: auto;
  position: relative;
  flex-grow: 1;
  min-height: 390px;
  @media screen and (max-width: 480px) {
    > div:last-of-type {
      padding: 0;
    }
  }
}

.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.chartContainer {
  position: relative;
}

.chart {
  position: absolute;
  top: 112px;
  left: 20px;
  right: 20px;
  bottom: 0px;
  @media screen and (max-width: 480px) {
    left: 0;
    width: 100%;
  }
}

text {
  fill: var(--text-gray) !important;
}
.wrapper {
  display: flex;
  width: 100%;
}
