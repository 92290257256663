.modal {
  height: fit-content !important;
  width: 900px;
  padding: 0;

  @media screen and (max-width: 480px) {
    width: 95% !important;
    max-width: unset !important;
  }
}

.iframe {
  width: 100%;
  height: 490px;
  @media screen and (max-width: 768px) {
    height: 400px;
  }
  @media screen and (max-width: 480px) {
    height: 265px;
  }
  @media screen and (max-width: 390px) {
    height: 230px;
  }
}
