.UserSettingsPage {
  display: flex;
  width: 100%;
  height: 100%;
  padding: 0 48px 24px 48px;
  align-items: stretch;
  gap: 20px;
  overflow-y: auto;
}

.container {
  padding-left: 60px;
  padding-right: 60px;
  padding-top: 70px;
}

.title {
  font-size: 18px;
  font-weight: 600;
}

.tabs {
  flex: 1;
}

.right {
  flex-grow: 1;
  flex-basis: 600px;
  display: flex;
  width: 600px;
  max-width: 60%;
  flex-direction: column;
}

.column {
  width: 780px;
  gap: 20px;
  flex-direction: column;
  display: flex;
  position: relative;
  left: 270px;
  margin-bottom: 40px;
  @media screen and (max-width: 1200px) {
    left: 0;
    max-width: unset;
    width: unset;
  }
}

.layout {
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
}
