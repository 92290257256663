.container {
  position: relative;
  height: 8px;
  display: flex;
  flex-direction: row;
  align-items: center;
  background: var(--input-outline);
  border-radius: 14px;
}

.indicator {
  border-radius: 24px;
  height: 100%;
  background: var(--blue-lighter);
}

.orangeIndicator {
  background: var(--warning);
}

.redIndicator {
  background: var(--red);
}
