.content {
  display: flex;
  flex-direction: column;
  gap: 50px;
  width: 100%;
  height: 100%;
  .title {
    font-size: 36px;
    font-weight: 600;
    @media (max-width: 480px) {
      font-size: 26px;
    }
  }

  .subtitle {
    font-size: 14px;
  }

  @media (max-width: 480px) {
    justify-content: space-evenly;
  }
}

.container {
  display: flex;
  background-color: #fff;
  padding: 50px 76px;
  border-radius: 15px;
  width: clamp(10%, 600px, 90%);
  overflow-y: auto;
  height: min(90%, 750px);
  margin: auto;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  justify-content: center;
  position: relative;
  overflow: hidden;
  @media (max-width: 480px) {
    padding: 24px 20px;
    height: unset;
    margin: unset;
  }
}

.blueBackground {
  background: var(--blue-gradient);
  box-shadow: 0px 10px 15px #00000014;
  backdrop-filter: blur(10px);

  position: fixed;
  max-height: 100%;
  overflow-y: hidden !important;
  max-width: 100%;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  will-change: opacity;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  align-content: center;
  z-index: 200;
}

.back {
  position: absolute;
  top: 34px;
  left: 80px;
  width: 34px;
  height: 14px;
  text-align: center;
  font: normal normal bold 14px/17px Metropolis, sans-serif;
  letter-spacing: 0px;
  color: #ffffff;
  cursor: pointer;
  display: flex;
  align-items: center;
  gap: 20px;
  p {
    font: normal normal bold 14px/17px Metropolis, sans-serif;
    letter-spacing: 0px;
    color: #ffffff;
  }

  @media (max-width: 480px) {
    top: 30px;
    left: 24px;
  }
}

.logout {
  font-size: 16px;
  font-weight: 500;
  position: absolute;
  left: 56px;
  bottom: 50px;
  color: #fff;
  cursor: pointer;
  @media screen and (max-width: 480px) {
    position: static;
    align-self: flex-start;
    margin-left: 24px;
  }
}

.addWorkspaceMobile {
  @media screen and (max-width: 480px) {
    position: static;
    width: 100%;
    gap: 12px;
  }
}
