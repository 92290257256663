.WorkspaceSettingsPage {
  width: 100%;
  height: 100%;
  padding: 0 48px 24px 48px;
  align-items: stretch;
  gap: 20px;
  overflow-y: auto;
}

.content {
  display: flex;
  align-items: stretch;
  flex-flow: row;
  gap: 20px;
}

.left {
  flex: 1;
  display: flex;
  flex-direction: column;
  gap: 20px;

  @media screen and (max-width: 1200px) {
    position: relative;
    top: 32px;
    margin-bottom: 32px;
  }
  @media screen and (max-width: 480px) {
    top: 0;
    margin-bottom: 0;
  }
}

.leftContainer {
  max-height: 100%;
  gap: 20px;
}

.right {
  flex-grow: 1;
  flex-basis: 600px;
  display: flex;
  width: 600px;
  max-width: 60%;
  gap: 20px;
  flex-direction: column;

  @media screen and (max-width: 1200px) {
    max-width: unset;
    width: 100%;
    margin-bottom: 32px;
  }

  @media screen and (max-width: 480px) {
    height: fit-content;
    flex-basis: 0px;
  }
}

.title {
  font-size: 18px;
  font-weight: 600;
  height: 30px;
}

.subtitle {
  font: normal normal bold 14px/17px Metropolis, sans-serif;
  letter-spacing: 0px;
  color: #0a2540;
}

.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.indicatorContainer {
  height: 24px;
  padding: 6px 8px;
  background: #e6fae5 0% 0% no-repeat padding-box;
  border-radius: 3px;
  display: flex;
  align-items: center;
  gap: 6px;
  p {
    color: #009d25;
    font: normal normal 600 12px/14px Metropolis, sans-serif;
  }
}

.indicator {
  background: #009d25 0% 0% no-repeat padding-box;
  width: 8px;
  height: 8px;
  border-radius: 50%;
}

.statusContainer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex: 1;
}

.card {
  display: flex;
  flex-direction: column;
  border: 1px solid var(--divider-gray);
  padding: 30px 20px;
  border-radius: 8px;
  gap: 20px;
}

.cardSubtitle {
  font: normal normal bold 14px/17px Metropolis, sans-serif;
  letter-spacing: 0px;
  color: #0a2540;
}

.workspacePlanContent {
  display: flex;
  flex-direction: column;
  gap: 24px;
}

.contentTop {
  display: flex;
  flex-direction: column;
  gap: 12px;
}
.contentBottom {
  display: flex;
  flex-direction: column;
  gap: 12px;
}

.highlightsContainer {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 12px;
  @media screen and (max-width: 480px) {
    grid-template-columns: unset;
  }
}

.highlightsCard {
  border: 1px solid var(--divider-gray);
  border-radius: 8px;
  padding: 12px 20px;
  display: flex;
  gap: 12px;
  align-items: center;
  h4 {
    font: normal normal normal 12px/14px Metropolis, sans-serif;
    letter-spacing: 0px;
    color: #0a2540;
    opacity: 0.5;
  }
  h5 {
    font: normal normal bold 14px/17px Metropolis, sans-serif;
    letter-spacing: 0px;
    color: #0a2540;
  }
}

.container {
  > div:last-child {
    padding: 24px 30px 36px 24px;
  }
}
