.tableHeader {
  display: flex;
  flex-direction: column;
  tr {
    text-align: center;
    font-size: 11px;

    div {
      font-weight: 300;
    }

    color: var(--text-gray);

    th {
      padding: 12px 6px;
      box-sizing: border-box;
      flex-shrink: 0;
      flex-basis: auto;
    }
  }
}

.campaignsTableHeader {
  border-bottom: 1px solid var(--divider-gray);
  position: sticky;
  top: 0;
  z-index: 100;
  background: white;

  tr {
    height: 36px;
    max-height: 36px;
    min-height: 36px;

    @media screen and (max-width: 1500px) {
      align-items: center;
      height: 38px;
      max-height: 38px;
      min-height: 38px;
    }
    th {
      &:first-child,
      &:nth-child(2) {
        position: sticky;
        z-index: 3;
        background: white;
      }
      &:first-child {
        left: 0;
      }
      &:nth-child(2) {
        left: 54px;
      }
      @media screen and (max-width: 1500px) {
        padding: 0;
        padding-bottom: 10px;
      }
    }
  }
}

.headerCell {
  display: flex;
  flex-direction: row;
  align-items: center !important;
  gap: 4px;
}

.sortIcons {
  display: flex;
  flex-direction: column;
  gap: 2px;
}

.alignedCenter {
  justify-content: center;
}
.alignedFlexStart {
  justify-content: flex-start;
}
.alignedFlexEnd {
  justify-content: flex-end;
}

.boldHeaderText {
  font: normal normal bold 12px/14px Metropolis, sans-serif;
  letter-spacing: 0px;
  color: var(--black);
}

.pointer {
  cursor: pointer;
}
