.App {
  display: flex;
  flex-wrap: wrap;
  height: 100%;
  @media screen and (max-width: 480px) {
    // overflow-y: hidden;
    min-height: 100vh;
    height: 100vh;
  }
}
