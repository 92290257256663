.menuItem {
  display: flex;
  height: 36px;
  gap: 8px;
  color: var(--black);
  font: normal normal normal 14px/26px Metropolis, sans-serif;
  border-radius: 4px;
  letter-spacing: -0.14px;
  cursor: pointer;
  width: 210px;
  position: relative;
  align-items: center;

  @media screen and (max-width: 1200px) {
    width: 100%;
  }
  @media screen and (max-width: 480px) {
    margin-left: 14px;
  }
}

.text {
  line-height: 20px;
}

.adsActive {
  font: normal normal 700 14px/26px Metropolis, sans-serif;
  color: var(--black) !important;
}

.errorIcon {
  position: absolute;
  right: 4px;
  top: 50%;
  transform: translate(-50%, -50%);
  @media screen and (max-width: 480px) {
    right: 18px;
  }
}

.title {
  font: normal normal bold 14px/26px Metropolis, sans-serif;
  letter-spacing: 0px;
  color: #7e8c9a;
}

.section {
  display: flex;
  flex-direction: column;
  gap: 16px;
}

.container {
  display: flex;
  flex-direction: column;
  gap: 48px;
}

.menuItemControl {
  @extend .menuItem;
  font: normal normal bold 14px/26px Metropolis, sans-serif;
  color: var(--link-color) !important;
}
