.ResetPasswordPage {
  display: flex;
  flex-flow: row wrap;
  width: 100%;
  height: 100%;
}

.firstHalf {
  display: flex;
  flex-direction: column;
  width: 50%;
  background-color: white;
  box-sizing: border-box;
  flex-grow: 1;
  align-items: center;

  @media screen and (max-width: 480px) {
    justify-content: center;
    gap: 16px;
    padding: 30px 20px;
  }
}

.firstHalfContent {
  display: flex;
  flex-direction: column;
  width: 60%;
  max-width: 512px;
  margin: auto 0;
  gap: 48px;
  @media screen and (max-width: 480px) {
    width: 100%;
  }
}

.secondHalf {
  width: 50%;
  background-color: var(--blue);
  color: white;
  text-rendering: optimizeLegibility;
  @media screen and (max-width: 950px) {
    display: none;
  }
}

.name {
  display: flex;
  flex-grow: 10;

  gap: 20px;
  @media screen and (max-width: 1350px) {
    flex-direction: column;
  }
}

.checkMailBoxText {
  margin-top: 20px;
}

.column {
  display: flex;
  flex-direction: column;
  gap: 16px;
}

.subtitle {
  font: normal normal normal 14px/26px Metropolis, sans-serif;
}

.container {
  display: flex;
  flex-direction: column;
  gap: 54px;
}
