.menu {
  flex: 0 1 210px;
  padding-top: 5px;
  display: flex;
  flex-direction: column;
  position: fixed;
  width: 270px;
}

.menuItem {
  display: flex;
  height: 36px;
  gap: 8px;
  color: var(--black);
  font: normal normal normal 14px/26px Metropolis, sans-serif;
  border-radius: 4px;
  letter-spacing: -0.14px;
  cursor: pointer;
  width: 210px;
  position: relative;
  align-items: center;
  @media screen and (max-width: 1200px) {
    width: 100%;
  }

  @media screen and (max-width: 480px) {
    margin-left: 14px;
    width: calc(100% - 14px);
  }
}

.active {
  position: absolute;
  left: -14px;
  background-color: var(--bg-gray);
  border-radius: 4px;
  padding: 10px 0px 10px 20px;
  z-index: -2;
  width: calc(100% + 14px);
  height: 100%;
}

.activeText {
  color: var(--black) !important;
  font: normal normal bold 14px/26px Metropolis, sans-serif;
  @media screen and (max-width: 480px) {
    margin-left: 14px;
    width: calc(100% - 14px);
  }
}

.text {
  line-height: 1;
}

.container {
  display: flex;
  flex-direction: column;
  gap: 4px;
}

.errorIcon {
  position: absolute;
  right: 4px;
  top: 50%;
  transform: translate(-50%, -50%);
}
