.container {
  border-radius: 8px;
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 0 2px 6px rgb(34 34 34 / 4%);

  ol {
    padding-inline-start: 18px;
  }

  p {
    font-size: 14px;
  }

  li > p {
    margin: 0;
  }
}

.contentSegment {
  flex: 1;
  padding: 24px 30px;
  @media screen and (max-width: 480px) {
    padding: 30px 20px;
  }
}

.headerSegment {
  min-height: 80px;
  padding: 24px 30px;
  @media screen and (max-width: 480px) {
    padding: 30px 20px;
  }
}
