.bannerCard {
  width: 414px;
}

.container {
  display: flex;
  justify-content: space-between;
  gap: 12px;
  img {
    align-self: center;
  }
}

.title {
  font: normal normal bold 16px/19px Metropolis, sans-serif;
  letter-spacing: 0px;
  color: var(--black);
  line-height: 1;
}

.text {
  font: normal normal normal 14px/22px Metropolis, sans-serif;
  letter-spacing: 0px;
  color: var(--black);
}

.top {
  display: flex;
  gap: 16px;
  flex-direction: column;
}

.left {
  display: flex;
  gap: 24px;
  flex-direction: column;
}

.linkText {
  font: normal normal bold 14px/26px Metropolis, sans-serif;
  letter-spacing: -0.14px;
  color: #2e58ff;
  cursor: pointer;
  line-height: 1;
}
