.modal {
  width: 520px;

  @media screen and (max-width: 480px) {
    width: 350px;
    height: 550px;
    padding: 50px 20px;
  }
}

.content {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
}

.text {
  text-align: center;
  font: normal normal normal 14px/26px Metropolis, sans-serif;
  letter-spacing: -0.14px;
  color: var(--black);
  margin: 20px 0 30px 0;
}

.highlighted {
  font: normal normal bold 14px/26px Metropolis, sans-serif;
}

.subtext {
  text-align: center;
  font: normal normal normal 14px/30px Metropolis, sans-serif;
  letter-spacing: 0px;
  color: var(--black);
  margin: 24px 0 0 0;
}

.header {
  text-align: center;
  font: normal normal bold 24px/30px Metropolis, sans-serif;
  letter-spacing: 0px;
  color: var(--black);
}

.btn {
  height: 54px;
}
