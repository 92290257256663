.radioButton {
  box-sizing: border-box;
  padding: 0;
  position: relative;
  display: inline-flex;
  align-items: center;
  flex: none;
  cursor: pointer;
  gap: 10px;
  span {
    font-weight: normal;
    font-size: 14px;
  }
  .radioButtonInner {
    margin-right: 18px;
    //no browser default radio
    input {
      position: absolute;
      opacity: 0;
      height: 0;
      width: 0;
    }
    span {
      position: absolute;
      top: 1px;
      left: 0;
      height: 16px;
      width: 16px;
      border: 1px solid var(--input-outline);
      border-radius: 50%;
      transition: border 0.25s linear;
      -webkit-transition: border 0.25s linear;
      &::after {
        content: "";
        position: absolute;
        display: none;
        top: 2px;
        left: 2px;
        width: 8px;
        height: 8px;
        border: 2px solid #fff;
        border-radius: 50%;
      }
    }
  }
  &:hover input:not(:checked) ~ span {
    border: 2px solid var(--input-outline);
  }
  input:checked ~ span {
    background: var(--blue);
    border: none;
  }
  input:checked ~ span::after {
    display: block;
  }
}
