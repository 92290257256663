.column {
  gap: 20px;
  flex-direction: column;
  display: flex;
  position: relative;
  left: 270px;
  margin-bottom: 40px;
  max-width: min(calc(100% - 270px), 780px);
  flex: 1;

  @media screen and (max-width: 1200px) {
    left: 0;
    max-width: unset;
  }
}

.layout {
  display: flex;
  gap: 20px;
  flex-direction: column;
  @media screen and (max-width: 480px) {
    width: 100%;
  }
}

.rest {
  display: flex;
  flex-direction: row;
  gap: 70px;
  height: 100%;
  @media screen and (max-width: 1200px) {
    flex-direction: column;
  }
  @media screen and (max-width: 480px) {
    gap: 36px;
  }
}

.menu {
  flex: 0 1 210px;
  padding-top: 5px;
  display: flex;
  flex-direction: column;
  position: fixed;
  width: 270px;
  @media screen and (max-width: 1200px) {
    position: relative;
    width: 100%;
    flex: 0;
  }
}
