.passwordInputWrapper {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  position: relative;
}

.formContainer {
  display: flex;
  flex-direction: column;

  & > button {
    margin-top: 10px;
  }
}

.divider {
  margin: 40px -60px 20px -60px;
  border-bottom: 1px solid #f0f2f6;
}

.error {
  margin-top: 10px;
}

label {
  font: inherit;
  font-size: 14px;
  font-weight: 600;
  flex: 1;
}

.row {
  display: flex;
  gap: 27px;
  width: 100%;
  flex-direction: column;
  & > * {
    flex: 1;
  }
}

i .label {
  font: inherit;
  font-size: 14px;
  font-weight: 600;
  margin-bottom: 10px;
}

.changePassword {
  cursor: pointer;
  position: absolute;
  right: 10px;
  top: 5px;
  color: var(--blue);
  font-size: 12px;
  font-weight: bold;
}

.container {
  max-height: 100%;
  position: relative;
  flex: 0;
}

.form {
  gap: 0 !important;
}

.title {
  font-size: 18px;
  font-weight: 600;
  line-height: 1;
}

.header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  min-height: 32px;
  @media screen and (max-width: 480px) {
    width: 100%;
  }
}

.wrapper {
  gap: 24px !important;
  display: flex;
  flex-direction: column;
  @media screen and (max-width: 480px) {
    width: 100%;
  }
}

.errorMessageWrapper {
  width: 420px;
  margin-left: auto;
}

.btnContainer {
  display: flex;
  width: 100%;
  justify-content: center;
  button {
    width: 144px;
    padding: 0 10px;
  }
}
