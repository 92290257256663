.overview {
  flex: 1;
  flex-basis: 400px;
  display: flex;
  max-height: 390px;

  @media screen and (max-width: 480px) {
    max-height: unset;
  }
}

.top {
  display: flex;
  justify-content: space-between;
  width: 100%;
  align-items: center;
  & > :first-child {
    font-weight: 600;
    font-size: 18px;
  }

  @media screen and (max-width: 480px) {
    flex-direction: column;
    margin-bottom: 0;
    gap: 20px;
  }
}

.switch {
  display: flex;
  align-items: center;
  gap: 16px;
  font-size: 12px;
  z-index: 95;

  button {
    transition: none;
  }

  @media screen and (max-width: 480px) {
    flex-direction: column;
    align-items: flex-start;
    width: 100%;
  }
}

.noDataContainer {
  @media screen and (max-width: 1200px) {
    justify-content: center;
  }

  @media screen and (max-width: 480px) {
    width: 100%;
  }
}

.segmentContainer {
  max-width: 1080px;
  width: min(calc(100% - 450px), 1080px);
  transform: translateX(-226px);

  @media screen and (max-width: 1500px) {
    max-width: unset;
    min-width: 100%;
    transform: unset;
  }

  @media screen and (max-width: 480px) {
    width: 100%;
  }
}

.content {
  display: flex;
  flex-direction: column;
  gap: 20px;
  flex: 1;
  align-items: center;
  width: 100%;
}

.dashboardContainer {
  display: flex;
  flex-direction: column;
  gap: 24px;
}

.banners {
  position: fixed;
  right: 48px;
  display: flex;
  gap: 20px;
  flex-direction: column;
}

.title {
  font: normal normal bold 20px/24px Metropolis, sans-serif;
  letter-spacing: 0px;
  @media screen and (max-width: 480px) {
    justify-self: flex-start;
    width: 100%;
    margin-left: 8px;
  }
}
