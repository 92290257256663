.smallScreenButton {
  border: 1px solid rgba(156, 164, 173, 0.2);
  border-radius: 40px;
  padding: 16px;
  height: 40px;
  width: 40px;
  z-index: 154;
  background-color: inherit;
  visibility: visible !important;
  * {
    visibility: visible !important;
  }

  > img {
    position: relative;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 18px;
    height: 18px;
  }
}

.whiteBg {
  background-color: white !important;
}

.workspaceSwitch {
  color: var(--text-black);
  box-shadow: none;
  padding: 0 20px;
  z-index: 154;
  transition: all 300ms ease;
  font-weight: 600;
  font-size: 14px;
  background-color: transparent;
  height: 48px;
  @media screen and (max-width: 480px) {
    height: 40px;
    border: 1px solid #e2e4e7;
    border-radius: 20px;
  }
  & > * {
    gap: 20px;
    @media screen and (max-width: 480px) {
      gap: 12px;
    }
  }

  > div > div {
    line-height: 0;
  }

  visibility: visible !important;
  * {
    visibility: visible !important;
  }
}

.workspaceName {
  font-size: 14px;
  line-height: 1;
}
