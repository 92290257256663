.banner {
  border-radius: 8px;
  display: flex;
  padding: 14px 16px 14px 12px;
  gap: 90px;
  align-items: flex-start;
  justify-content: space-between;

  @media screen and (max-width: 1025px) {
    gap: 40px;
  }
  @media screen and (max-width: 480px) {
    gap: 28px;
  }
}

.blue {
  background: #e0e6fe 0% 0% no-repeat padding-box;
  color: #2e58ff;
}
.red {
  background: #ffebeb 0% 0% no-repeat padding-box;
  color: #ff4040;
}
.gray {
  background: #9ca4ad14 0% 0% no-repeat padding-box;
  color: #9ca4ad;
}

.text {
  font: normal normal 500 14px/21px Metropolis, sans-serif;
  a {
    text-decoration: underline;
    font-weight: 500 !important;
    color: inherit;
  }
}

.left {
  display: flex;
  gap: 8px;
  align-items: flex-start;
}

.closeIcon {
  cursor: pointer;
}
