.dropdownItem {
  display: flex;
  height: 40px;
  min-height: 40px;
  gap: 12px;
  align-items: center;
  padding: 0 8px;
  &:hover {
    background: #f4f5f799 0% 0% no-repeat padding-box;
    border-radius: 4px;
  }
}

.itemTextContainer {
  display: flex;
  align-items: center;
  gap: 8px;
}

.itemText {
  line-height: 1;
}
