@import "../../index.scss";

.formContainer {
  display: flex;
  flex-direction: column;
  width: 100%;

  & > button {
    margin-top: 10px;
  }
}

.divider {
  margin: 40px -60px 20px -60px;
  border-bottom: 1px solid #f0f2f6;
}

.error {
  margin-top: 10px;
}

label {
  font: inherit;
  font-size: 14px;
  font-weight: 600;
  flex: 1;
}

.row {
  display: flex;
  gap: 24px;
  width: 100%;
  flex-direction: column;
  & > * {
    flex: 1;
  }

  @media screen and (max-width: 480px) {
    flex-direction: column;
  }
}

.imageSection {
  display: flex;
  gap: 30px;
  justify-content: space-between;
  align-items: center;
  width: 420px;
  @media screen and (max-width: 480px) {
    width: 100%;
    gap: 16px;
  }
}

.imageInput {
  @extend .basic-input;
  display: flex;
  background-color: white;
  padding: 14px 16px 14px 24px;
  cursor: pointer;
  font: normal normal normal 14px/26px Metropolis, sans-serif;
  letter-spacing: -0.14px;
  align-items: center;
}

.imageInputRight {
  display: flex;
  justify-content: space-between;
  width: 100%;
  padding-left: 17px;
}

.previewImage {
  line-height: 0;
  & > img {
    border-radius: 50%;
  }
}

.previewImageWrapper {
  display: flex;
  align-items: center;
  gap: 10px;
  > img {
    cursor: pointer;
  }
}

input#file-upload-button {
  display: none !important;
}

input[type="file"] {
  display: none;
}

.passwordInputWrapper {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  position: relative;
}

.changePassword {
  cursor: pointer;
  position: absolute;
  right: 10px;
  top: 5px;
  color: var(--blue);
  font-size: 12px;
  font-weight: bold;
}

.container {
  max-height: 100%;
  position: relative;
  flex: 0;
}

.form {
  gap: 0 !important;
}

.title {
  font-size: 18px;
  font-weight: 600;
  line-height: 1;
}

.header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  min-height: 32px;
}

.imageInputWrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
  @media screen and (max-width: 480px) {
    flex-direction: column;
    align-items: flex-start;
    gap: 10px;
  }
}

.btnContainer {
  display: flex;
  width: 100%;
  justify-content: center;
  button {
    width: 134px;
    padding: 0 10px;
  }
}
