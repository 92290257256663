.container {
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: center;
  user-select: none;
  img {
    -webkit-user-drag: none;
    -khtml-user-drag: none;
    -moz-user-drag: none;
    -o-user-drag: none;
  }
}

.content {
  display: flex;
  gap: 2px;
  align-items: center;
}

.text {
  font-family: Metropolis, sans-serif;
  font-size: 12px;
  letter-spacing: -0.12px;
  color: #9ca4ad;
  opacity: 1;
  line-height: 1;
}
