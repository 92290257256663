body {
  margin: 0;
  background-color: var(--background-gray);

  font-family: "Metropolis", -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell",
    "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  color: var(--text-black);
  text-rendering: geometricPrecision;
}

body,
html,
#root {
  height: 100%;
  margin: 0;
  @media screen and (max-width: 480px) {
    height: unset;
  }
}

p,
h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0;
}

ul {
  margin: 0;
  padding: 0;
  list-style-type: none;
}

p {
  font-size: 14px;
}

@media screen and (max-width: 480px) {
  p {
    font-size: 13px;
    line-height: 1.5;
  }
}

* {
  box-sizing: border-box;
  line-height: 1.4;
}

a {
  text-decoration: none;
  color: var(--link-color);
  font-weight: 600;
}

.errorMessage {
  color: var(--alert);
  display: block;
  font-size: 14px;
}

//
div[title="Contact us button"] > div,
div[title="Live chat button"] > div {
  z-index: 150 !important;
  @media screen and (max-width: 480px) {
    transform: scale(0.8) !important;
    bottom: 80px !important;
    right: 8px !important;
  }
}

:root {
  --background-gray: #f4f5f7;
  --dark-gray: #505050;
  --blue: #132bb1;
  --blue-gradient: transparent linear-gradient(117deg, #132bb1 0%, #0020d1 100%) 0% 0% no-repeat padding-box;
  --light-blue: #2968ce;
  --text-black: #252e3e;
  --border-gray: #8e959e;
  --success: #14c476;
  --success-notification-text: #009d25;
  --success-notification-bg: #e5f7ef;
  --warning: #ff990f;
  --base-red: #f26a6e;
  --red: #ff4040;
  --red-hover: #f93636;
  --alert: #e34a2a;
  --text-gray: #8e959e;
  --input-outline: #dcdfe4;
  --header-height: 96px;
  --light-gray: #7e8c9a;
  --black: #0a2540;
  --icon-gray: #9ca4ad;
  --bg-gray: #e5e8ed;
  --blue-light: #5476fe;
  --bg-warning: #fff5e6;
  --bg-success: #e5f7ef;
  --bg-alert: #ffebeb;
  --icon-green: #42c560;
  --blue-lighter: #2d49e3;
  --gray-darker: #c6c9cc;
  --link-color: #2e58ff;
  --divider-gray: #ebeef0;
}

form {
  display: flex;
  flex-direction: column;
  // flex-flow: column wrap;
  gap: 16px !important;
}
// font
@font-face {
  font-family: "Metropolis";
  font-style: normal;
  font-weight: 600;
  src: url("./fonts/metropolis/Metropolis-Bold.otf") format("opentype");
}

@font-face {
  font-family: "Metropolis";
  font-style: normal;
  font-weight: 600;
  src: url("./fonts/metropolis/Metropolis-SemiBold.otf") format("opentype");
}

@font-face {
  font-family: "Metropolis";
  font-style: normal;
  font-weight: 500;
  src: url("./fonts/metropolis/Metropolis-Medium.otf") format("opentype");
}
@font-face {
  font-family: "Metropolis";
  font-style: normal;
  font-weight: 400;
  src: url("./fonts/metropolis/Metropolis-Regular.otf") format("opentype");
}

@font-face {
  font-family: "Metropolis";
  font-style: normal;
  font-weight: 300;
  src: url("./fonts/metropolis/Metropolis-Light.otf") format("opentype");
}

.basic-input {
  border: 1px solid var(--input-outline) !important;
  border-radius: 8px !important;
  height: 50px;
  box-shadow: none !important;
}

.select__control {
  @extend .basic-input;
}

.select__indicator {
  padding: 8px 0 8px 8px !important;
}
