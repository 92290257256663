.LoginPage {
  display: flex;
  flex-flow: row wrap;
  width: 100%;
  height: 100%;
}

.firstHalf {
  display: flex;
  flex-direction: column;
  width: 50%;
  background-color: white;
  box-sizing: border-box;
  flex-grow: 1;
  align-items: center;

  @media screen and (max-width: 480px) {
    justify-content: center;
    gap: 16px;
    padding: 30px 20px;
  }
}

.firstHalfContent {
  display: flex;
  flex-direction: column;
  width: 60%;
  max-width: 512px;
  margin: auto 0;
  gap: 48px;
  @media screen and (max-width: 480px) {
    width: 100%;
  }
}

.secondHalf {
  display: flex;
  width: 50%;
  background-color: #3d33a9;
  color: white;
  text-rendering: optimizeLegibility;
  @media screen and (max-width: 950px) {
    display: none;
  }
}

.form {
  display: flex;
  flex-flow: column wrap;
  gap: 27px !important;
}

.row {
  display: flex;
  gap: 6px;
  @media screen and (max-width: 480px) {
    justify-content: center;
  }
}

.column {
  display: flex;
  flex-direction: column;
  gap: 16px;
}

.multiRow {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-flow: row wrap;
  align-content: center;
  gap: 10px;

  & > * {
    display: flex;
    gap: 5px;
    align-items: center;
  }
}

.logo {
  display: flex;
  height: 32px;
  margin-bottom: 30px;
  @media screen and (max-width: 480px) {
    position: static;
  }
}

.buttons {
  margin-top: 12px;
  display: flex;
  gap: 20px;
  font-size: 14px;
  flex-direction: row;
}

.subtitle {
  font: normal normal normal 14px/26px Metropolis, sans-serif;
}