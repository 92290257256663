.container {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: var(--blue);
}

.slider {
  display: flex;
  height: 50%;
  align-items: flex-end;
  flex-grow: 1;
  overflow: hidden;
}

.slide {
  flex-shrink: 0;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: white;
  text-rendering: optimizeLegibility;
  transition: ease 1000ms;
}

.dots {
  display: flex;
  flex-grow: 1;
  justify-content: center;
  align-items: flex-start;
}
.dot {
  display: flex;
  height: 16px;
  width: 16px;
  border-radius: 50%;
  cursor: pointer;
  margin: 0 7px 0px;
  background-color: #ffffff79;
}

.active {
  background-color: #ffffff;
}

.text {
  margin: 0 auto;
  margin-top: 18px;
  margin-bottom: 38px;
  max-width: 350px;
  text-align: center;
  @media screen and (max-width: 900px) {
    max-width: 100%;
  }
}

.img {
  max-width: 60%;
  max-height: 40%;
}
